import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/system";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.appBar + 1,
  transition: "background-color 0.3s ease",
  backdropFilter: "blur(10px)",
}));

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center", // Align content vertically
  width: "100%", // Ensure it spans the viewport width
  padding: "0 16px", // Avoid excessive padding
  boxShadow: "none", // Remove unnecessary shadow
  margin: 0, // Reset any default margins
});

const NavButtons = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const MobileMenuButton = styled(IconButton)(({ theme, isSpecialPage }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "block",
    color: isSpecialPage ? "white" : "black",
  },
}));

const StyledButton = styled(Button)(({ theme, isSpecialPage }) => ({
  color: isSpecialPage ? "white" : "black",
  fontFamily: theme.typography.fontFamily,
  "&:hover": {
    backgroundColor: isSpecialPage
      ? "rgba(255, 255, 255, 0.1)"
      : "rgba(0, 0, 0, 0.1)",
  },
  fontWeight: "semibold",
  margin: "0 10px",
}));

function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();

  // Check for special pages that should have white text
  const isSpecialPage =
    location.pathname === "/" ||
    location.pathname.startsWith("/credit-cards/") || // Match any route under /credit-cards/
    location.pathname === "/banking-products" ||
    location.pathname === "/products";

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { label: "Home", path: "/" },
    { label: "Products", path: "/products" },
    { label: "About", path: "/about" },
  ];

  return (
    <StyledAppBar>
      <StyledToolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{
            flexGrow: 0,
            color: isSpecialPage ? "white" : "black",
            textDecoration: "none",
            fontWeight: "bold",
            marginRight: "auto",
          }}
        >
          ArthGyan
        </Typography>
        <NavButtons>
          {menuItems.map((item) => (
            <StyledButton
              key={item.path}
              component={Link}
              to={item.path}
              isSpecialPage={isSpecialPage}
            >
              {item.label}
            </StyledButton>
          ))}
        </NavButtons>
        <MobileMenuButton
          edge="end"
          aria-label="menu"
          onClick={handleMenuOpen}
          isSpecialPage={isSpecialPage}
        >
          <MenuIcon />
        </MobileMenuButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {menuItems.map((item) => (
            <MenuItem
              key={item.path}
              component={Link}
              to={item.path}
              onClick={handleMenuClose}
              sx={{ color: "black" }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      </StyledToolbar>
    </StyledAppBar>
  );
}

export default Navbar;
