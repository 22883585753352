import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import Navbar from "./Components/Navbar.js";
import HomePage from "./Components/Homepage.js";
import CreditCardsPage from "./Components/CreditCardsPage.js";
import BankingProductsScreen from "./Components/BankingProductsScreen.js";
import ComingSoonPage from "./Components/ComingSoonPage.js";
import AboutUsPage from "./Components/AboutUsPage.js";
import TermsAndConditions from "./Components/TermsAndConditions.js";
import FAQPage from "./Components/FAQPage.js";
// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // You can customize this color
    },
    secondary: {
      main: "#f50057", // You can customize this color
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HashRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<BankingProductsScreen />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/coming-soon" element={<ComingSoonPage />} />
          <Route path="/banking-products" element={<BankingProductsScreen />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/faq-page" element={<FAQPage />} />
          <Route path="/credit-cards/:id" element={<CreditCardsPage />} />
        </Routes>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
