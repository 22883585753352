import React from "react";
import {
  Box,
  Typography,
  Container,
  Paper,
  Divider,
  CssBaseline,
} from "@mui/material";

const TermsAndConditions = () => {
  return (
    <>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: "1.5rem" }}>
            Terms & Conditions - Arthgyan.com
          </Typography>

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              1. Introduction:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Thank you for visiting www.Arthgyan.com or the Arthgyan mobile
              application (together the “Arthgyan”)– India’s Largest Cashback
              Site www.Arthgyan.com. The Cashback Service is operated by Pouring
              Pounds Ltd and/or Pouring Pounds India Private Limited (“We”,
              “Us”, “Arthgyan” or “Our” where such expression shall unless
              repugnant to the context thereof, be deemed to include its
              respective legal heirs, representatives, administrators, permitted
              successors and assigns). By using the various services available
              on the Arthgyan Platform (“Services”), you consent to these terms,
              guidelines and supplemental terms provided to you for the Services
              that you use (collectively, “Terms”) and Arthgyan’s efforts to
              improve every User’s experience on the platform. Please read these
              terms and conditions (“Terms and Conditions” or “Agreement”)
              carefully as they contain the legal terms and conditions that you
              agree to when you use the service provided by us through the
              Website and the App (“Platform”, “Website”). We will also notify
              our registered members of material changes (if any) to these terms
              and conditions by either sending a notice to the email address
              provided to us at the time of signing-up or by placing a pop-up on
              our website/mobile application(s). Be sure to visit this page
              periodically to review the most recent version of the Agreement.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              2. Membership:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Our membership is available through registration process after you
              submit certain requested information to Arthgyan. A user must read
              and click on the checkbox to accept the prevailing Terms and
              Conditions which the User needs to adhere to while availing Our
              services.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Please be aware that a member of Arthgyan is not allowed to run
              any paid ads on Google, Facebook or any other platform pointing
              to Arthgyan. If the member does not comply with this then it would
              result in immediate termination of his/her account.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              3. Declaration:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              The purchases made by You using the platform of Pouring Pounds
              will be used only for bona fide personal purposes. You shall not
              use Arthgyan Platform to purchase anything to resell for
              commercial or business purposes, to derive any financial gain.
              Pouring Pounds also reserves the right to block/ terminate the
              User's account on Arthgyan Platform without any prior notice if,
              based on its risk management criteria, it reasonably believes that
              Arthgyan Platform is used for any nonauthorized purposes.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Reward programs allow redemption through gift cards or wallets,
              and unique referral fees provide additional benefits to Members
              introducing new users to the platform.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              4. Becoming a Member:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              If You are a natural person, (i) as a condition to Your use of the
              Platform, You must be 18 (eighteen) years of age or older; and
              (ii) by visiting the Platform or accepting these Terms and
              Conditions, You represent and warrant to Arthgyan that You are 18
              (eighteen) years of age or older, and that You have the right,
              authority and capacity to use the Platform and agree to and abide
              by these Terms and Conditions; provided however that if You are a
              parent or legal guardian of a person younger than 18 (eighteen)
              years of age, You are permitted to access and use the Platform for
              the purposes of procuring the Services, on behalf of such persons.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              You must register for the Cashback Service using accurate and
              current information about yourself – including your correct name,
              address and any other requested details. If you are asked for, and
              provide, details of a bank account, UPI or other payment mode
              details through which you wish to receive the pay-outs (your
              “Cashback Receipt Method”), you (a) must ensure that you are, and
              remain, fully entitled to use that Cashback Receipt Method, and
              (b) confirm that you wish to receive cashback through that
              Cashback Receipt Method. You should keep this information updated
              through your Account. We reserve the right to discontinue or
              temporary put on hold any payment methods listed on Our site, be
              it UPI, Bank Transfer or Wallet/Gift Card.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Generation and collection of ‘Sensitive Personal Data or
              Information’ is in accordance with Information Technology Act,
              2000 as amended from time to time and allied rules requires the
              User’s express consent. By clicking on the “I agree with Terms and
              Policy” button at the time of registration, the User provides
              affirming assent to such information generation and collection as
              required under applicable laws.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Note that your Cashback Receipt Method (such as Bank Transfer,
              Gift Cards/ Wallets, UPI or other payment modes as We may
              introduce in the near future) provides a minimum Cashback
              threshold limit that a User needs to reach or attain in order to
              withdraw & receive Cashback through the opted Cashback Receipt
              Method.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Important: You must ensure that the email address and mobile
              number we hold for you is kept up-to-date and that you have full
              access to it – We will be sending you important updates via text
              messages & Emails, App Notifications and in some cases via
              WhatsApp. If you change your email address or mobile number, then
              you must change the details we hold for you on your Account.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              5. Cashback/Reward and Referral Fees:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              After a Member successfully completes a Qualifying Transaction,
              and once we have received the resulting Cashback/Reward for that
              Qualifying Transaction, we pass that Cashback/Reward to the Member
              through his/her Cashback Receipt Method.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Please note that there are various circumstances in which a
              transaction with a Retailer may not constitute a Qualifying
              Transaction, or Cashback/Rewards may not result from it. This is
              also true for a Qualifying Referral. The Member understands that
              all transactions made by him/her are not with us, but with the
              individual Retailer. Our help pages provide further information
              about these circumstances. Further, from time to time Retailers
              may increase or decrease the commission paid – in which case the
              cashback/Reward offer illustrated on our Platform may be
              incorrect/outdated. By default, your applicable transactions will
              be credited in line with the commission reported to us by the
              Retailer, which may be more or less than the advertised rate. We
              shall not be liable for any difference in the expected
              cashback/reward by the Member and the actual Cashback/reward
              received by the Member.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              When we have traced a paying, Qualifying Transaction to your
              Account, we will credit your Account with a ‘pending payment’.
              Once the Retailer confirms the transaction, the payment will be
              marked as ‘validated’ – this can take 90 days or more from the date
              of the purchase. If the goods are returned or the sale reversed or
              amended by any means, then the cashback/reward payment will not be
              made. Apart from the Retailer, it is also mandatory for the
              members to intimate Arthgyan about any returns or exchanges for
              which they might have earned cashback/reward unduly.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Whether a transaction shall qualify as a Qualifying Transaction
              (including cashback through a Qualifying Referral) shall be at
              the sole discretion of the Retailer or Us and the Member
              understands and agrees to the same. We shall not be held
              responsible if the Retailer fails to report a sale to us or does
              not qualify a transaction as Qualifying Transaction. Further, we
              shall not be responsible in the event due to some technical or
              other errors we cannot trace a sale back to the Member and does
              not qualify the transaction as a Qualifying Transaction or a
              Qualifying Referral for any reason whatsoever. Whilst we will
              endeavour to recuperate non-payments from the Retailer, the
              decision of ourselves, the Retailer or Retailer’s tracking agent
              shall stand final. Whilst we will try and recuperate missing
              commissions, at any point as well as, we reserve the right not to
              chase missing commission claims, particularly where no purchase
              has been made.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Further, in the event that the Retailer feels that the purchase is
              not genuine for any reason whatsoever and we do not receive any
              Cashback/Rewards for the transaction, the Member will not receive
              any Cashback/Rewards.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              When a Member sends us an enquiry on missing cashback/reward via
              raising a ticket, our system would check if the user had an exit
              click from our Platform for that store on the date mentioned by
              the user. If no exit clicks can be tracked, then the Member will
              be indicated that they did not click via our website to earn
              cashback/reward prior to the sale. All exit clicks to stores are
              saved in the database for cross reference on adding cashback/reward
              values. Where a commission enquiry has been successfully paid by a
              Retailer, you may receive a lower amount than expected; this is due
              to the amounts received by ourselves possibly being lower than the
              original claim. We do allow you to raise a query with us. If a
              Retailer still hasn’t paid a manual commission claim after a period
              of six months, then we reserve the right to close the enquiry claim.
              All payment for Missing Cashback/Reward is only applicable in
              instances where the Retailer pays Arthgyan.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Note: The User raising or intending to raise a ticket in case of
              an enquiry of missing cashback/reward shall raise the same within
              10 (Ten) days from the date of the transaction. No claim shall be
              entertained by any registered User post the stipulated time-line.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              We reserve the right to reclaim or make balance adjustments
              accordingly where it has been established that any credit has been
              applied in error. This will include but is not limited to
              transactions where the credit is not genuinely due or payment for
              any credited transaction has not been received from a Retailer or
              its agencies and/or misuse or fraud. This can include transactions
              which are already marked as payable or validated in your Account,
              or for transactions which have already been paid over to you by
              Bank Transfer, Gift Cards/wallets, UPIs or any other payment modes
              as We may introduce from time to time. In all such incidences,
              Arthgyan has the right to recover all unduly paid cashback/rewards
              which the member is not entitled to earn, through legal
              proceedings.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              The User while raising a pay-out request, shall ensure choosing
              the withdrawal method (Bank Transfer, Gift Card/wallet, UPIs, etc.)
              containing the correct credentials and if the customer provides
              incorrect details and we process the pay-out(s), we shall not bear
              any liability against the same. Such, negligence shall be seen as
              the User’s error.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              There are various circumstances in which Cashback/Rewards will not
              be payable to the Member, and will be forfeited by us, these could
              be, without limitation:
            </Typography>
            <Typography variant="body1" component="ul" paragraph sx={{ fontSize: "0.875rem" }}>
              <li>
                where the cashback/reward payment that we receive is not
                attributed to a Qualifying Transaction or associated with an
                Account (such as where the Member is not logged-in to our
                Platform when making the relevant purchase)
              </li>
              <li>
                the transaction to which the Cashback/reward relates is
                cancelled after it has been entered into (whether under the
                right of cancellation that applies to some sales made at a
                distance, or otherwise)
              </li>
              <li>
                the Cashback/Reward is attributed to a Member or Account that
                has been:
                <ul>
                  <li>suspended by us under Clause 9 of this Agreement or for
                    any other reason;</li>
                  <li>associated with any fraudulent activity or any breach of
                    this Agreement;</li>
                </ul>
              </li>
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Our help pages provide further information about these
              circumstances.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              6. Your Account:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              You can withdraw/redeem your validated cashback/rewards when you have
              attained the minimum cashback/reward threshold in your Account. In
              the event the Member decides to close the Account with us, and if at
              such time of closure, the validated Cashback/rewards in your account
              is less than the minimum threshold limit set for your account then
              the entire amount will be forfeited. If the validated Cashback/Reward
              in your account is touching or is more than the minimum threshold
              limit set for your account, then you can request payment of the same
              via any of the Cashback/Reward Receipt Methods. We have the
              discretion to forfeit any such validated balance showing on your
              Account for the reasons provided elsewhere in this Agreement.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              7. Validity of Cashback/Rewards/Referral/Special bonus
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Acknowledgment: You acknowledge that the Arthgyan Earnings will expire if you do not login to your Arthgyan account (via website or application) for a continuous period of 2 years.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Preventing expiry of earnings: In order to prevent your Earnings from getting expired, you must login to your Arthgyan account via website or application at least once every 2 years.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Expired earnings: Upon expiry, this amount shall be removed from Order Details page and will be listed under "Expired Cashbacks" menu as a single entry. Such expired Earnings will not be withdrawable by you.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              8. Intellectual Property:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              You acknowledge that all copyright, trademarks, and other intellectual property rights in and relating to the Platform (including the material which is contributed by Members or Retailers) are owned by, or licensed to, Us. Copying material from our portal may seem easy but is illegal and strict actions would be taken against the User/visitor under applicable laws. Therefore, no-one may copy, distribute, show in public or create any derivative work from the Platform, or any of the material which is found on the Platform unless properly licensed to do so by Us.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              By uploading or including any material on the Platform, a Member expressly grants:
            </Typography>
            <Typography variant="body1" component="ul" paragraph sx={{ fontSize: "0.875rem", pl: 4 }}>
              <li>
                to Us a non-exclusive license (including the right to grant sub-licenses) to use, reproduce and distribute that material through our Cashback/Reward Service and any other interactive services through which we or our sub-licensee make the Cashback/Reward Service (or a service based on our service) available; and
              </li>
              <li>
                to other Members (through us, under the license referred to in a. above), the non-exclusive, personal, non-transferable right to view the relevant material.
              </li>
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              9. Privacy Policy:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Our Privacy Policy forms part of this Agreement, and by entering into this Agreement you also give your consent to the way we handle your personal data under that policy. Given the global nature of the World Wide Web, please note that a posting on the Platform may be accessible to internet users around the world.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              10. Our Role:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              We are not a party to any transactions with Retailers, and are not the seller or supplier of any of the goods or services that they make available. Thus, we do not have any of the legal obligations that apply to the sellers of those goods or services.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Accordingly, we have no control over or responsibility for:
            </Typography>
            <Typography variant="body1" component="ul" paragraph sx={{ fontSize: "0.875rem", pl: 4 }}>
              <li>
                the quality, safety, or legality of those goods or services available from Retailers; or
              </li>
              <li>
                whether the Retailer can or will supply and pass good title to any goods or services.
              </li>
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Members should exercise no lesser degree of caution in entering into transactions with Retailers than they would when entering into a similar transaction offline. To the extent that the Applicable Law permits, You release Us, Our Agents and employees from all liability arising out of or in connection with any transactions with Retailers, including (without limitation) all claims and demands relating to uncompleted or completed transactions with Retailers, or goods or services offered for sale or supply, or actually sold or supplied, through or in connection with any transactions with Retailers.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              11. Misuse:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              We reserve the right to suspend or terminate any Members access to our service, or parts of it, if in our reasonable view the said Member or Account appears to be in breach of any provision of this Agreement.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Members must not enter into, or attempt to enter into, any transaction with a Retailer or to gain Cashback/Rewards (a) by providing personal information of someone else, or a payment method which they are not entitled to use, (b) by deceptively or unfairly exploiting a Retailers' offering including but not limited to creating fake or unauthorized referral links, or (c) in breach of any terms and conditions applied by Us or the Retailer to that transaction. We reserve the right to forfeit any pending payments or validated payments from the Member's Account in case of such misuse of our service by the Member.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              It is each Member's obligation to ensure that any material posted by him/her or associated with his/her Account:
            </Typography>
            <Typography variant="body1" component="ul" paragraph sx={{ fontSize: "0.875rem", pl: 4 }}>
              <li>is not defamatory, offensive, or abusive or of an obscene, indecent or menacing nature;</li>
              <li>is not intended or likely to cause needless annoyance, inconvenience or distress to any person;</li>
              <li>does not contain any computer virus, macro virus, Trojan horse, worm, or anything else designed to interfere with, interrupt, or disrupt the normal operating procedures of a computer or to surreptitiously intercept, access without authority, or expropriate any system, data or personal information;</li>
              <li>does not contravene the Applicable Law or regulation (including, but not limited to, laws governing consumer protection, distance selling, unfair competition, anti-discrimination, false advertising, information technology, copyright, trademark and privacy);</li>
              <li>does not breach the rights of any person or entity (including any rights or expectations of privacy);</li>
              <li>must be accurate and fair in relation to the feedbacks of Retailers</li>
              <li>does not advertise any goods or services.</li>
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Notwithstanding anything contained elsewhere in this Agreement and the Privacy Policy, we reserve the right to investigate complaints or reported violations of this Agreement and to take any action we deem appropriate, including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to your Account, email addresses, usage history, posted materials, IP addresses and traffic information. Any Member, who in the sole discretion of the Retailer or Us, has committed any kind of fraud or has misused Our Platform or our Cashback/Reward Service their Account will be closed with immediate effect and the,registered , Email and IP address will also be blacklisted thereby discontinuing the Member to use or register with the same credentials or IP address in the near future.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              If You see or experience anything on Our Platform that appears to infringe any of the above requirements, We would like You to inform Us by using Our contact form.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Each Member acknowledges that we are entitled, but not obliged, to withdraw any material, which appears – based on information received from third parties or other Members – to be in breach of this Agreement.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              12. Contact from third parties:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              If anyone contacts Us in relation to material or transactions associated with You or your Account, then you agree:
            </Typography>
            <Typography variant="body1" component="ul" paragraph sx={{ fontSize: "0.875rem", pl: 4 }}>
              <li>
                to provide all reasonable information and assistance as required by Us,in connection with responding to that contact; and
              </li>
              <li>
                to respond promptly and accurately to it, should We pass the message to You for a response.
              </li>
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              13. Additional services:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              We or Our partners may offer new or additional services through these Platform from time to time. Your use of those services may be subject to additional terms and conditions, which you must comply with. Provided that those terms are notified to you on the Platform in an appropriate manner (as determined by us in our reasonable discretion) when you agree to take those services, any failure by you to comply with a material provision of the terms governing those services will amount to a breach of this Agreement.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              14. Operation of our Cashback/Reward Service:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              We reserve the right to withdraw, modify or suspend aspects of the Cashback/Reward Service, or the entirety of it, where we have legal, security, technical or commercial reasons to do so. We will endeavour to give You 30 (Thirty) days advance notice before taking such action, except where it is necessary to take earlier action for security reasons or because of technical difficulties which would otherwise adversely affect our service. There may also be times when the Cashback/Reward Service becomes inaccessible as a result of technical difficulties experienced by Us or on the Internet; We will, however, use reasonable skill and care to overcome these difficulties where they are within our control. Please note, however, that we cannot guarantee continuous access to the Cashback/Reward Services or any of the content that appears on it.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Nevertheless we shall inform the Users about service unavailability, system maintenance or server down on our Portal and shall keep such situations to arise to the bare minimum.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Nevertheless, We shall strive to ensure that any periods or situations such as service unavailability, system maintenance or server down would be pre-informed to the Member and shall strive to keep such situations to arise to the minimum in order to avoid the Members coming across any hurdles while availing Our services.
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              For security or other reasons, we may require you to change or update password or other information which facilitates access to the Cashback/Reward Service; however, we will never ask you for Your password via email, telephone, or any other means other than through the Platform. You are solely responsible for maintaining the confidentiality of your password and any additional identifying information.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              15. Disclaimer and Limitation of Liability:
            </Typography>

            <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "1.1rem", fontWeight: "bold", mt: 2 }}>
              Disclaimer:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              The content and material from or through the Platform are provided "as-is," "as available," with "all faults", and all warranties, express or implied, are disclaimed (including but not limited to the disclaimer of any implied warranties of merchantability, non-infringement, freedom from error, and fitness for a particular purpose). The information and services may contain bugs, errors, problems or other limitations. We and Our affiliated parties have no liability whatsoever for Your use of any information or service, except as provided in sub-section 13(d). In particular, but not as a limitation thereof, We and Our affiliated parties are not liable for any indirect, special, incidental or consequential damages (including damages for loss of business, loss of profits, savings, litigation, or the like), whether based on breach of contract, breach of warranty, tort (including negligence), product liability or otherwise, even if advised of the possibility of such damages. The negation and limitation of damages set forth above are fundamental elements of the basis of the agreement between Arthgyan and you. This Platform and the products, services, documents, content and materials and information presented would not be provided without such limitations. No advice or information, whether oral or written, obtained by You from Us through the Platform or otherwise shall create any warranty, representation or guarantee not expressly stated in this Agreement. All responsibility or liability for any damages caused by viruses contained within the electronic file containing a form or document is disclaimed.
            </Typography>

            <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "1.1rem", fontWeight: "bold", mt: 2 }}>
              Liability:
            </Typography>
            <Typography variant="body1" component="ul" paragraph sx={{ fontSize: "0.875rem", pl: 4 }}>
              <li>
                We warrant that the Cashback/Reward Service will be provided with reasonable care and skill with the intention of meeting our specifications for the Cashback/Reward Service, but we cannot and do not guarantee that the Cashback/Reward Service will meet your requirements.
              </li>
              <li>
                We shall be liable as expressly provided in this Agreement, but shall have no other obligation, duty or liability whatsoever in contract, tort (including negligence, breach of statutory duty and any other tort) or otherwise.
              </li>
              <li>
                Limitation of Liability: Subject always to sub-Clause d. below, we shall be liable for direct loss or damage only, whether in contract, tort (including negligence, breach of statutory duty or other tort) or otherwise, and whether caused by its act or omission or that of its employees, agents or subcontractors. The aggregate liability of Arthgyan and the affiliated parties in connection with any claim arising out of or relating to the Platform and/or the products, information, documents and services provided herein or hereby shall not exceed Rs 500 and that amount shall be in lieu of all other remedies which you may have against us and any affiliated party to us.
              </li>
              <li>
                We will not be liable to you or anyone else, whether in contract, tort (including negligence, breach of statutory duty or other tort) or otherwise:
                <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                  <li>(A) for any loss of revenue, business, anticipated savings or profits;</li>
                  <li>(B) any errors in or omissions from the Platform or any services or products obtainable therefrom;</li>
                  <li>(C) the unavailability or interruption of the Platform or any features thereof;</li>
                  <li>(D) your use of the Platform;</li>
                  <li>(E) the content and materials contained on the Platform;</li>
                  <li>(F) or any delay or failure in performance beyond our control or any of our affiliated parties.</li>
                </ul>
              </li>
              <li>
                for any indirect, special or consequential loss damage, costs or other claims, howsoever caused or arising, whether through non-supply or late supply of the Cashback Service or other non-performance of this Agreement or otherwise.
              </li>
              <li>
                Except as expressly stated elsewhere in this Agreement, all representations, warranties, conditions and other terms, whether express or implied (by common law, statute, collaterally or otherwise) are hereby excluded, except in the case of fraud, or where such exclusion is not permitted by Applicable Law.
              </li>
              <li>
                For the avoidance of doubt, we will not have liability to you or any other person in respect of material contributed by Members, transactions (or non-transactions) with Retailers, or any activity or communication relating to such material or transactions.
              </li>
            </Typography>

            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              The provisions of this Clause 14 shall survive the termination or expiry of this Agreement.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              16. Third Party Content:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Third party content and materials may appear on the Platform or may be accessible via hyperlinks from the Platform. We are not responsible for and assume no liability whatsoever for any mistakes, misstatements of law, defamation, omissions, falsehood, obscenity, pornography or profanity in the statements, opinions, representations or any other form of content and materials appearing on the Platform or accessible via hyperlinks from the Platform.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              17. Communications:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              You hereby expressly agree to receive communications from time to time from Arthgyan, its affiliates, partners and third parties, regarding the Service rendered, important updates, feedbacks & surveys, marketing or any other relevant messages by way of SMS, e-mails, calls (automated/ agent generated), WhatsApp notifications, mobile application and browser notifications or any other medium. You agree and consent to receive communications relating to all of the above and expressly waive any registration or preference made under DND/NCPR list under the applicable TRAI regulations.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              18. Indemnity:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              You agree to indemnify us against all liabilities, claims and expenses that may arise out of or in connection with (a) any breach of this Agreement by You or through Your Account, or (b) any transaction with a Retailer.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              19. Assignment:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              We reserve the right to assign this Agreement, and to assign or subcontract any or all of our rights and obligations under this Agreement, but will not do so in such a way as to reduce any guarantees you are given under this Agreement. You may not without our written consent assign or dispose of this Agreement, nor subcontract any of your rights and obligations under it.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              20. Entire Agreement:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              This Agreement is intended to contain your entire agreement with us relating to the Cashback/Reward Service; we believe it to be fair and reasonable. It replaces all earlier agreements and understandings with you relating to the Cashback/Reward Service, except for any fraud or fraudulent representation by either of us.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              21. Changes to this Agreement:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              We reserve the right to change this Agreement from time to time, and post the new version on the Cashback/Reward Service. When we do so, we will post the new version of the Agreement on the Cashback/Reward Service, and the new version of these terms and conditions will take effect, and will govern the Cashback/Reward Service and your relationship with us:
            </Typography>
            <Typography variant="body1" component="ul" paragraph sx={{ fontSize: "0.875rem", pl: 4 }}>
              <li>
                commencing no less than 3 (three) days after the date of posting (or such later date as We indicate in the relevant posting), if any of the changes is to an operative provision of this Agreement which is capable of adversely affecting You; if You do not wish to be governed by the new version of the Agreement, You may notify Us on or before the date when the new version of the Agreement is to take effect, and from that date You must cease to use Our service or
              </li>
              <li>
                immediately upon the date of posting (or such later date as We indicate in the relevant posting), if the changes are not to operative provisions, or not capable of adversely affecting You – examples of which would include, without limitation, changes to contact details referred to, or the refinement of provisions that are already included, in this Agreement.
              </li>
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              22. Severability:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              In the event that any term of this Agreement is held to be invalid or unenforceable, the remainder of this Agreement shall remain valid and enforceable. You and Arthgyan are independent contractors, and no agency, partnership, joint venture or employee-employer relationship is intended or created by this Agreement. Our failure to act with respect to a breach by You or others does not waive our right to act with respect to subsequent or similar breaches.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              23. Grievance Officer:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              In compliance with Information Technology Act, 2000 and the rules made thereunder and the Consumer Protection (E-Commerce) Rules,2020, the details of the Grievance Officer of Arthgyan for the purpose of this Agreement is as follows:
            </Typography>
            
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem", pl: 2 }}>
              Name: Priyaranjan Thakur<br />
              Designation: Director of Customer Experience<br />
              Address: Plot No C-9, Asha Officers Colony RK Puram, Secunderabad,Hyderabad<br />
              Email ID: pthakur@arthgyan.com
            </Typography>

            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              In the event, any aggrieved user, having any questions or intends to make a complaint regarding any violation of the provisions of these Terms of Use, may send a written complaint to the Grievance officer who shall redress the complaint within 1 (One) month from receiving the complaint.
            </Typography>
            
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              Note: We at Our own discretion may modify the aforesaid details from time to time. Providing false, misleading or inaccurate information to the grievance officer may result in civil and/ or criminal liability.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              24. Governing Law:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              This Agreement, and our relationship with you and each Member, is governed as per the Indian Laws. You and we each submit to the non-exclusive jurisdiction of the Indian courts in relation to disputes arising in connection with this Agreement.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              25. Keeping this Agreement:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              We don't separately file the individual Agreements entered into by Members when they register for the Cashback Service. You can access it at www.Arthgyan.com. Please make a durable copy of this Agreement by printing and/or saving a downloaded copy on your own computer. It is offered in English only.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.2rem" }}>
              26. Contact:
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "0.875rem" }}>
              You can reach us on 'pthakur@arthgyan.com'.
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />
        </Paper>
      </Container>
    </>
  );
};

export default TermsAndConditions;
