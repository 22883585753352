import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Select,
  MenuItem,
  ButtonBase,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import creditCards from "../JSON/creditCardData.json"; // JSON file
import Navbar from "./Navbar";
import Footer from "./Footer";

const BankingProductsScreen = () => {
  const navigate = useNavigate();
  const [visibleCards, setVisibleCards] = useState(6); // Initially show 6 cards
  const [isLoading, setIsLoading] = useState(false); // Loading state for spinner
  const [filter, setFilter] = useState("recommended"); // Filter state
  const [bankFilter, setBankFilter] = useState(""); // Bank dropdown filter

  const handleCardClick = (id) => {
    navigate(`/credit-cards/${id}`);
  };

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const handleBankFilterChange = (event) => {
    setBankFilter(event.target.value);
  };

  const filteredCards = creditCards
    .filter((card) => {
      if (filter === "recommended") return card.recommended === true;
      if (filter === "free") return card.type === "free";
      if (filter === "secured") return card.type === "secured";
      if (filter === "fuel") return card.type === "fuel";
      return true;
    })
    .filter((card) => {
      if (bankFilter) {
        const bankName = card.hero.title.split(" ")[0].toLowerCase(); // Extract bank name from title
        return bankName === bankFilter.toLowerCase();
      }
      return true;
    });

  const loadMoreCards = useCallback(() => {
    if (filteredCards.length > visibleCards) {
      setIsLoading(true);
      setTimeout(() => {
        setVisibleCards((prev) => prev + 4);
        setIsLoading(false);
      }, 1000);
    }
  }, [filteredCards.length, visibleCards]);

  const handleScroll = useCallback(() => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = window.innerHeight;

    if (scrollTop + clientHeight >= scrollHeight - 500 && !isLoading) {
      loadMoreCards();
    }
  }, [isLoading, loadMoreCards]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          width: "100%",
          height: "300px",
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://t4.ftcdn.net/jpg/02/81/89/73/360_F_281897358_3rj9ZBSZHo5s0L1ug7uuIHadSxh9Cc75.jpg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          color: "white",
        }}
      >
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          sx={{
            fontFamily: '"Nato Sherif", serif',
            fontWeight: "bold",
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
          }}
        >
          Banking Products
        </Typography>
      </Box>
      <Box sx={{ paddingBottom: 3 }} />
      <Box
        sx={{
          width: "90%",
          marginX: "auto",
          padding: 3,
          backgroundColor: "#f5f5f5",
          borderRadius: "16px",
          marginTop: { xs: "-50px", sm: "-75px", md: "-100px" },
          marginBottom: "2rem",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1.5rem",
            flexWrap: "wrap",
            gap: "2rem",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              marginLeft: { xs: 0, sm: "20%" },
            }}
          >
  <ToggleButtonGroup
  value={filter}
  exclusive
  onChange={handleFilterChange}
  aria-label="filter"
  sx={{
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "8px",
    "& .MuiToggleButton-root": {
      width: "auto",
      height: "40px",
      fontSize: { xs: "0.75rem", sm: "0.875rem" },
      padding: { xs: "0 8px", sm: "0 12px" },
      border: "1px solid #d0d0d0", // Light border
      borderRadius: "4px",
      backgroundColor: "#ffffff", // Light background
      color: "#000000", // Black text
      "&.Mui-selected": {
        backgroundColor: "#81BFDA", // Blue for selected state
        color: "#000000", // Black text for selected
        fontWeight: "bold",
        borderColor: "#81BFDA",
      },
      "&:hover": {
        backgroundColor: "#f0f0f0", // Slightly darker light background for hover
        borderColor: "#b0b0b0",
        color: "#000000", // Keep text black on hover
      },
    },
  }}
>
  <ToggleButton value="recommended" aria-label="recommended">
    Recommended
  </ToggleButton>
  <ToggleButton value="free" aria-label="free">
    Life Time Free
  </ToggleButton>
  <ToggleButton value="secured" aria-label="secured">
    Secured Card
  </ToggleButton>
  <ToggleButton value="fuel" aria-label="fuel">
    Fuel
  </ToggleButton>
</ToggleButtonGroup>


            
          </Box>

          <Select
  value={bankFilter}
  onChange={handleBankFilterChange}
  displayEmpty
  variant="outlined"
  sx={{
    width: { xs: "100%", sm: "200px" }, // Adjust width for smaller size
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "50px", // Reduced height
      fontSize: "0.75rem", // Smaller font size
      padding: "5px px", // Adjusted padding
      transition: "transform 0.2s, box-shadow 0.2s",
      "&:hover": {
        transform: "scale(1.05)",
        boxShadow: 6,
      },
    },
    "& .MuiSelect-select": {
      padding: "4px 8px",
       // Adjusted padding for the text inside
    },
  }}
>
  <MenuItem
    value=""
    sx={{
      fontSize: "0.75rem",
      "&:hover": {
        backgroundColor: "#81BFDA", // Blue hover color
        color: "#000000", // White text on hover
      },
    }}
  >
    All Banks
  </MenuItem>
  {["SBI", "HDFC", "Axis", "IndusInd", "IDFC", "HSBC"].map((bank) => (
    <MenuItem
      key={bank}
      value={bank}
      sx={{
        fontSize: "0.75rem",
        "&:hover": {
          backgroundColor: "#81BFDA", // Blue hover color
          color: "#ffffff", // White text on hover
        },
      }}
    >
      {bank}
    </MenuItem>
  ))}
</Select>




        </Box>

        <Grid container spacing={3}>
          {filteredCards.slice(0, visibleCards).map((card) => (
            <Grid item xs={12} sm={6} md={3} key={card.id}>
              <ButtonBase
                onClick={() => handleCardClick(card.id)}
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Card
                  sx={{
                    transition: "transform 0.2s, box-shadow 0.2s",
                    borderRadius: "16px",
                    boxShadow: 3,
                    backgroundColor: "#ffffff",
                    display: "flex",
                    flexDirection: "column",
                    height: "350px",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: 6,
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={card.card.image}
                    alt={card.hero.title}
                    loading="lazy"
                    sx={{
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "800" }}
                      gutterBottom
                    >
                      {card.hero.title}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      backgroundColor: "#81BFDA",
                      borderBottomLeftRadius: "16px",
                      borderBottomRightRadius: "16px",
                      padding: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "#7AB2D3",
                        color: "#fff",
                      },
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Benefits & Eligibility
                    </Typography>
                  </Box>
                </Card>
              </ButtonBase>
            </Grid>
          ))}
        </Grid>

        {isLoading && filteredCards.length > visibleCards && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 3,
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default BankingProductsScreen;
