import React, { useEffect } from "react"
import { Container, Typography, Button, Box, Grid, List, ListItem } from "@mui/material";
import { styled } from "@mui/system"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"

import Footer from "./Footer"
import Navbar from "./Navbar"
import creditCardData from "../JSON/creditCardData.json" // Adjust the path as needed
import { useParams } from "react-router-dom"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const StyledHero = styled(Box)(({ theme, backgroundImage }) => ({
	backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${backgroundImage}')`,
	backgroundSize: "cover",
	backgroundPosition: "center",
	color: theme.palette.primary.contrastText,
	padding: theme.spacing(6, 0),
	textAlign: "center",
	height: "300px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
}))

function CreditCardsPage() {
	const { id } = useParams() // Get the id from the URL

	useEffect(() => {
		window.scrollTo(0, 0) // Scrolls instantly to the top-left of the page
	}, [])

	// Find the data for the selected card
	const selectedCard = creditCardData.find((card) => card.id === id)

	if (!selectedCard) {
		return (
			<Typography variant="h4" sx={{ textAlign: "center", marginTop: 4 }}>
				Credit Card Data Not Found
			</Typography>
		)
	}

	const { hero, card, applyNowUrl } = selectedCard

	const handleApplyNow = () => {
		window.open(applyNowUrl, "_blank", "noopener,noreferrer")
	}

	return (
		<>
			<StyledHero backgroundImage={hero.backgroundImage}>
				<Navbar />
				<Container maxWidth="xl">
					<Typography
						variant="h2"
						component="h1"
						gutterBottom
						sx={{
							fontFamily: '"Nato Sherif", serif',
							fontWeight: "bold",
							marginBottom: "1rem",
							fontSize: {
								xs: "2rem",
								sm: "2.5rem",
								md: "2.5rem",
							},
						}}>
						{hero.title}
					</Typography>
					<Typography
						variant="h5"
						paragraph
						sx={{
							marginBottom: "1.5rem",
							fontSize: { xs: "1rem", sm: "1rem", md: "1rem" },
						}}>
						{hero.subtitle}
					</Typography>
				</Container>
			</StyledHero>

			<Box sx={{ paddingX: 2, marginTop: -6 }}>
				<Box
					sx={{
						maxWidth: "400px", // Set a max width for the box
						maxHeight: "250px", // Set a max height for the box
						margin: "0 auto", // Center the box horizontally
						padding: 2,
						backgroundColor: "#d7eeff",
						borderRadius: "16px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<Box
						component="img"
						src={card.image}
						alt={hero.title}
						sx={{
							maxWidth: "90%", // Ensure the image fits within the width of the container
							maxHeight: "90%", // Ensure the image fits within the height of the container
							objectFit: "contain", // Preserve aspect ratio and fit the image within the box
							display: "block",
							borderRadius: "8px", // Add some rounding to the image
						}}
					/>
				</Box>
			</Box>

			<Box sx={{ padding: 3 }}>
				<Typography
					variant="h4"
					gutterBottom
					sx={{
						textAlign: "center",
						marginBottom: 3,
						fontSize: { xs: "1.8rem", sm: "2rem" },
						fontWeight: "bold",
					}}>
					Card Features
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						gap: 2,
						justifyContent: "center",
					}}>
					{card.features.map((feature, index) => (
						<Box
							key={index}
							sx={{
								display: "flex",
								alignItems: "center",
								backgroundColor: "#f4f4f4",
								borderRadius: 3,
								padding: 2,
								width: { xs: "100%", sm: "45%", md: "30%" },
								boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
							}}>
							<CheckCircleOutlineIcon
								color="primary"
								sx={{ marginRight: 2 }}
							/>
							<Typography variant="body1">{feature}</Typography>
						</Box>
					))}
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						marginTop: "2rem",
					}}>
					<Button
						variant="contained"
						onClick={handleApplyNow}
						sx={{
							width: { xs: "100%", sm: "45%", md: "30%" },
							padding: "10px 30px",
							borderRadius: 5,
							fontSize: "1rem",
						}}>
						Apply Now
					</Button>
				</Box>
			</Box>

	{/* Description and Eligibility Section */}
<Box
    sx={{
        padding: 3,
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        marginTop: 4,
    }}>
    <Grid container spacing={2}>
        {/* Description */}
        {card.description && (
            <Grid item xs={12} md={6}>
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                        fontWeight: "bold",
                        fontSize: { xs: "1.5rem", sm: "1.8rem" },
                        textAlign: "center",
                    }}>
                    Description
                </Typography>
                <List>
                    {card.description.map((item, index) => (
                        <ListItem
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                gap: 1,
                            }}>
                            <FiberManualRecordIcon
                                fontSize="small"
                                sx={{ color: "primary.main", marginTop: "4px" }}
                            />
                            <Typography variant="body1">{item}</Typography>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        )}

        {/* Eligibility */}
        {card.eligibility && (
            <Grid item xs={12} md={6}>
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                        fontWeight: "bold",
                        fontSize: { xs: "1.5rem", sm: "1.8rem" },
                        textAlign: "center",
                    }}>
                    Eligibility Criteria
                </Typography>
                <List>
                    {card.eligibility.map((item, index) => (
                        <ListItem
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                gap: 1,
                            }}>
                            <FiberManualRecordIcon
                                fontSize="small"
                                sx={{ color: "primary.main", marginTop: "4px" }}
                            />
                            <Typography variant="body1">{item}</Typography>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        )}
    </Grid>
</Box>



			<Footer />
		</>
	)
}

export default CreditCardsPage
