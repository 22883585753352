import React from "react";
import { Button, Typography, Container, Paper, Box } from "@mui/material";
import { FormInput } from "lucide-react";
import Lottie from "react-lottie";
import * as animationData from "../lottie-animation.json"; // Adjust path to your Lottie JSON file

const ComingSoonPage = () => {
  const handleGoogleForm = () => {
    window.open("https://forms.gle/3R146W9UuZrsAyCn7", "_blank");
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, // Path to your Lottie animation file
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Container
        component="main"
        maxWidth="false"
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center", // Centering the content
          background: "linear-gradient(to bottom, #E3F2FD, #BBDEFB)",
          padding: 0,
          position: "relative",
          overflow: "hidden",
          flexDirection: { xs: "column", sm: "row" }, // Stack on mobile and row on larger screens
        }}
      >
        {/* Background Lottie Animation aligned to the right */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0, // Aligns Lottie animation to the right
            width: { xs: "80%", sm: "50%", md: "45%" }, // Adjust width for different screen sizes
            height: { xs: "40%", sm: "50%", md: "100%" }, // Responsive height
            opacity: 0.8, // Slight opacity to blend in
            marginTop: { xs: 5, sm: 10 }, // Add margin-top to push the animation down on small screens
          }}
        >
          <Lottie options={defaultOptions} height="100%" width="100%" />
        </Box>

        {/* Content Box with Transparency */}
        <Paper
          elevation={6}
          sx={{
            padding: { xs: 3, sm: 4 }, // Padding adjusts for different screen sizes
            textAlign: "center",
            maxWidth: 400,
            width: "100%",
            borderRadius: 4,
            boxShadow: "0 12px 24px rgba(0, 0, 0, 0.1)",
            background: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
            position: "relative",
            zIndex: 1,
            marginTop: { xs: 5, sm: 10 }, // Margin for spacing on small screens
            marginBottom: { xs: 3, sm: 5 }, // Bottom margin adjustment
          }}
        >
          <Box
            sx={{
              position: "relative",
              marginBottom: 3,
              "&::before": {
                content: '""',
                position: "absolute",
                top: "-12px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "50px",
                height: "5px",
                backgroundColor: "#2196F3",
                borderRadius: "5px",
              },
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{ fontWeight: 600 }}
            >
              Coming Soon
            </Typography>
          </Box>

          <Typography
            variant="body1"
            color="textSecondary"
            gutterBottom
            sx={{ fontStyle: "italic" }}
          >
            We're working on something exciting. Stay tuned!
          </Typography>

          {/* Google Form Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoogleForm}
            sx={{
              width: "100%",
              marginTop: 3,
              padding: "12px",
              fontWeight: "bold",
              letterSpacing: "1px",
              borderRadius: "8px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#1976D2",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <FormInput className="w-5 h-5" style={{ marginRight: "8px" }} />
            Fill Our Form
          </Button>

          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginTop: 2,
              fontSize: "0.875rem",
              color: "#616161",
            }}
          >
            Share your interest by filling out our quick form
          </Typography>
        </Paper>
      </Container>
      {/* <Footer /> */}
    </>
  );
};

export default ComingSoonPage;
