import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  // Button,
  // Divider,
} from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#89CFF0",
        color: "black",
        py: { xs: 4, md: 6 },
        mt: "auto",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={4} sx={{ textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            {/* Use Link from react-router-dom for routing */}
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              Home
            </Link>
            <br />
            <Link
              to="/products"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              Products
            </Link>
            <br />
            {/* <Link
              to="/faq-page"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              FAQ Page
            </Link>
            <br />
            <Link
              to="/terms-and-conditions"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              Terms And Conditions
            </Link>
            <br /> */}
            <Link
              to="/about"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              About Us
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4} sx={{ textAlign: "right" }}>
            <Typography variant="h6" gutterBottom>
              Address
            </Typography>
            <Typography variant="body2">
              Plot No C-9, Asha Officers Colony
              <br />
              RK Puram, Secunderabad, Hyderabad
              <br />
              Phone: (9595027710)
              <br />
              Email: pthakur@arthgyan.com
            </Typography>
          </Grid>
        </Grid>

        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mt: 5 }}
        >
          © {new Date().getFullYear()} ArthGyani Technology Pvt. Ltd. All rights
          reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
