import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQPage = () => {
  return (
    <div
      style={{
        width: "80%",
        margin: "100px auto",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Frequently Asked Questions
      </Typography>

      <Accordion
        sx={{
          border: "1px solid #ccc",
          borderRadius: "8px",
          marginBottom: "10px",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#f9f9f9",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor: "#e3f2fd",
            "& .MuiAccordionSummary-content": {
              margin: "0px",
            },
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>What is Arthgyan?</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "15px",
            backgroundColor: "#fff",
          }}
        >
          <Typography>
            Arthgyan is India’s largest cashback and coupon site. It allows
            users to earn cashback by shopping online through its platform.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          border: "1px solid #ccc",
          borderRadius: "8px",
          marginBottom: "10px",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#f9f9f9",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{
            backgroundColor: "#e3f2fd",
            "& .MuiAccordionSummary-content": {
              margin: "0px",
            },
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            How does cashback work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "15px",
            backgroundColor: "#fff",
          }}
        >
          <Typography>
            When you shop through Arthgyan, the retailer pays us a commission.
            We share this commission with you as cashback.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          border: "1px solid #ccc",
          borderRadius: "8px",
          marginBottom: "10px",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#f9f9f9",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{
            backgroundColor: "#e3f2fd",
            "& .MuiAccordionSummary-content": {
              margin: "0px",
            },
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            How can I withdraw my cashback?
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "15px",
            backgroundColor: "#fff",
          }}
        >
          <Typography>
            You can withdraw your cashback directly to your bank account,
            provided you meet the minimum withdrawal threshold set by Arthgyan.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FAQPage;
